<template>
    <div id="home-fuel">
        <div class="fuel-logo">
            <img :src="$parent.logoURL" />
        </div>
        <b-container class="bv-example-row">
            <b-row>
                <b-col cols="6">
                    <router-link :to="$parent.base +'/editaccount'" tag="div" class="btn-group">
                        <div class="btn-circle">
                            <div class="picto-img picto-profile"></div>
                        </div>
                        <span>{{$t('myProfile')}}</span>
                    </router-link>
                </b-col>
                <b-col cols="6">
                    <router-link :to="$parent.base +'/selection'" tag="div" class="btn-group">
                        <div class="btn-circle">
                            <div class="picto-img picto-fuel"></div>
                        </div>
                        <span>{{$t('fuel')}}</span>
                    </router-link>
                </b-col>
                <b-col cols="6">
                    <router-link :to="$parent.base +'/cars'" tag="div" class="btn-group">
                        <div class="btn-circle">
                            <div class="picto-img picto-car"></div>
                        </div>
                        <span>{{$t('myCars')}}</span>
                    </router-link>
                </b-col>
                <b-col cols="6">
                    <router-link :to="$parent.base +'/coupons'" tag="div" class="btn-group">
                        <div class="btn-circle">
                            <div class="picto-img picto-coupon"></div>
                        </div>
                        <span>{{$t('myCoupons')}}</span>
                    </router-link>
                </b-col>
                <b-col cols="6">
                    <router-link :to="$parent.base +'/history'" tag="div" class="btn-group">
                        <div class="btn-circle">
                            <div class="picto-img picto-history"></div>
                        </div>
                        <span>{{$t('myHistory')}}</span>
                    </router-link>
                </b-col>
                <b-col cols="6">
                    <router-link :to="$parent.base +'/scan?showBasket=true'" tag="div" class="btn-group">
                        <div class="btn-circle">
                            <div class="picto-img picto-shop"></div>
                        </div>
                        <span>{{$t('myShop')}}</span>
                    </router-link>
                </b-col>

            </b-row>
        </b-container>
    </div>
</template>

<script>
    var Stats = require("@/assets/js/Stats");
    var Customer = require("@/assets/js/Customer");
    import {isNotNull} from "@/assets/js/Utils"

    export default {
        name: 'Home',
        mounted(){
            if(isNotNull(localStorage.getItem("DKC_fuel_token"))){
                Customer.getCustomer(customer => {
                    customer = JSON.parse(customer);
                    localStorage.setItem("DKC_fuel_loyalty", customer.loyalty.loyaltyPoint)
                })
                Stats.setStats({nbHomeAccess: 1});
            }
        }
    }

</script>